import React from 'react';

import { message } from 'antd';

import { AccommodationContext } from './AccommodationContext';
import AnnualRateIncrease from './AnnualRateIncrease';
import MarketRates from './MarketRates';

interface Props {
  community_id: any;
}

const AccommodationForm = ({ community_id }: Props) => {
  const [messageApi, contextHolder] = message.useMessage();

  const showMessage = (type: 'success' | 'error', message: string) => {
    messageApi.open({
      type: type,
      content: message,
    });
  };

  return (
    <AccommodationContext.Provider value={{ communityId: parseInt(community_id), showMessage: showMessage }}>
      {contextHolder}
      <MarketRates />
      <AnnualRateIncrease communityId={parseInt(community_id)} />
    </AccommodationContext.Provider>
  );
};

export default AccommodationForm;

import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { CheckCircleFilled } from '@ant-design/icons';
import { Button, InputNumber, Typography } from 'antd';
import styled from 'styled-components';

import { createCommunityLevelFees, getCommunityLevelFees, updateCommunityLevelFees } from 'apis/CommunityAPI';
import { useRootContext } from 'components/layout/RootContext';

import { CommunityLevelFee, CommunityLevelFeeOutbound } from './types';
import { transformCommunityLevelFeesInbound, transformCommunityLevelFeesOutbound } from './utils';

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-flow: column;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.span`
  font-size: 16px;
  margin: 0 10px;
`;

type Props = {
  communityId: number;
};

const AnnualRateIncrease = ({ communityId }: Props) => {
  const { showMessage } = useRootContext();

  const [communityLevelFees, setCommunityLevelFees] = useState<CommunityLevelFee | null>(null);
  const [annualRateIncreaseMin, setAnnualRateIncreaseMin] = useState<number>(0);
  const [annualRateIncreaseMax, setAnnualRateIncreaseMax] = useState<number>(0);

  const { data, refetch } = useQuery({
    queryKey: ['communityLevelFees', communityId],
    queryFn: () => getCommunityLevelFees(communityId, { active: 1 }),
    onSuccess: (data: CommunityLevelFeeOutbound[]) => {
      if (data[0]) {
        setCommunityLevelFees(transformCommunityLevelFeesInbound(data[0]));
        const { annual_rate_increase_min, annual_rate_increase_max } = data[0];
        setAnnualRateIncreaseMin(
          typeof annual_rate_increase_min === 'string' ? parseFloat(annual_rate_increase_min) : annual_rate_increase_min
        );
        setAnnualRateIncreaseMax(
          typeof annual_rate_increase_max === 'string' ? parseFloat(annual_rate_increase_max) : annual_rate_increase_max
        );
      } else {
        setAnnualRateIncreaseMin(0);
        setAnnualRateIncreaseMax(0);
      }
    },
    enabled: communityId > 0,
    refetchOnWindowFocus: false,
  });

  const increaseMin = (value: number | null) => {
    if (value !== null && value <= annualRateIncreaseMax) {
      setAnnualRateIncreaseMin(value);
    }
  };
  const increaseMax = (value: number | null) => {
    if (value !== null && value >= annualRateIncreaseMin) {
      setAnnualRateIncreaseMax(value);
    }
  };

  const updateAnnualRate = async () => {
    const payload = transformCommunityLevelFeesOutbound(
      communityLevelFees?.id,
      annualRateIncreaseMin,
      annualRateIncreaseMax
    );
    const updateFunction = data?.length ? updateCommunityLevelFees : createCommunityLevelFees;
    const queryParams = { active: 1 };
    await updateFunction(communityId, payload, queryParams)
      .then(() => {
        showMessage('success', 'Annual Rates updated successfully');
        refetch();
      })
      .catch((reason: any) => {
        showMessage('error', 'Trouble updating Annual Rates');
      });
  };

  const disableSubmit =
    annualRateIncreaseMax === annualRateIncreaseMin ||
    (communityLevelFees?.annualRateIncreaseMin == annualRateIncreaseMin &&
      communityLevelFees?.annualRateIncreaseMax == annualRateIncreaseMax);

  return (
    <Container>
      <Typography.Title level={3}>Annual Rate Increase</Typography.Title>
      <Inputs>
        <InputNumber
          addonAfter="%"
          min={0}
          max={100}
          size="middle"
          value={annualRateIncreaseMin}
          onChange={increaseMin}
        />
        <Label>to</Label>
        <InputNumber
          addonAfter="%"
          min={0}
          max={100}
          size="middle"
          value={annualRateIncreaseMax}
          onChange={increaseMax}
        />
        <Button
          icon={<CheckCircleFilled style={{ color: disableSubmit ? 'lightgrey' : 'rgb(22, 119, 255)' }} />}
          shape="round"
          disabled={disableSubmit}
          style={{ fontSize: 14 }}
          type="text"
          onClick={updateAnnualRate}
        />
      </Inputs>
    </Container>
  );
};

export default AnnualRateIncrease;

import React from 'react';

import { Button } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  padding: 60px 60px 234px 60px;
`;

const TalkButton = styled(Button)`
  width: 132px;
  height: 40px;
  padding: 10px 35px 11px 36px;
  border-radius: 20px;
  border: solid 1px rgba(255, 255, 255, 0.76);
  background: transparent;
  color: var(--white);
  font-family: var(--font-regular);
  font-size: 14px;
  margin-top: 35px;
  font-weight: 500;

  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    border: solid 1px rgba(255, 255, 255, 0.76);
    background: transparent;
    color: var(--white);
  }
`;

const Title = styled.p`
  font-family: var(--font-regular);
  font-size: 20px;
  color: #fff;
  text-align: center;
`;

const SubTitle = styled.p`
  font-family: var(--font-regular);
  font-size: 14px;
  line-height: 1.71;
  color: rgba(255, 255, 255, 0.69);
  margin-top: 23px;
  text-align: center;
`;

const AuthenticationSidePanelContent = () => {
  return (
    <Container>
      <Title>Optimize Senior Housing Revenue With Our Pricing Assistant</Title>
      <SubTitle>
        How does your community compare against your competition on what matters to families? <br /> Further can help!
      </SubTitle>
      <a href="https://www.talkfurther.com/pricing-assistant" target="_blank" rel="noopener noreferrer">
        <TalkButton type="default" shape="round">
          Let's Talk
        </TalkButton>
      </a>
    </Container>
  );
};

export default AuthenticationSidePanelContent;

import React from 'react';

import styled from 'styled-components';

import AuthenticationSidePanelContent from './AuthenticationSidePanelContent';

const Container = styled.div`
  width: 33.7vw;
  height: 100%;
  background-image: url('images/login.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  color: white;
  max-width: 100;
  box-sizing: border-box;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  padding: 85px 50px 85px 20px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AuthenticationSidePanel = () => {
  return (
    <Container>
      <ContentWrapper>
        <img src="logos/login_logo.svg" alt="logo" style={{}} />
        <img src="logos/login_group_logo.svg" alt="img" style={{ marginTop: '154px' }} />
        <AuthenticationSidePanelContent />
      </ContentWrapper>
    </Container>
  );
};

export default AuthenticationSidePanel;

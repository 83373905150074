import React, { useState } from 'react';

import { DeleteFilled } from '@ant-design/icons/lib';
import { Button, Popconfirm, Table, Tooltip, Typography } from 'antd';
import { format } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import styled from 'styled-components';

import axios from 'config/axiosPrivate';

import { CommunityFeeDateType, FREQUENCY_OPTIONS } from './constants';
import { useFeesContext } from './FeesContext';
import { mapCommunityFeeResponseToData } from './utils';
import { GLOBAL_DATE_FORMAT, MEDIA_BREAK_POINTS, STATUS } from '../../../constants';
import { useAppSelector } from '../../../store/hook';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const FeesFormHistoryCommunityFees = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const permissions = currentUser?.all_permissions;
  const missingDeletionPermission = permissions?.indexOf('PricingAssistant.delete_feesincentives') == -1;

  const { communityId, showMessage } = useFeesContext();

  const [historyData, setHistoryData] = React.useState<any[]>([]);
  const [dataLoading, setDataLoading] = useState(STATUS.IDLE);

  React.useEffect(() => {
    getCommunityFeesIncentives();
  }, [communityId]);

  const getCommunityFeesIncentives = () => {
    setDataLoading(STATUS.LOADING);
    axios
      .get(`/communities/${communityId}/fee-incentives?active=0`)
      .then((response) => {
        const data = response.data;
        const formatted_data: CommunityFeeDateType[] = data?.map((item: any) => mapCommunityFeeResponseToData(item));
        formatted_data.forEach((record) => {
          const frequency = FREQUENCY_OPTIONS.find((item) => item.value === record.frequency);
          record.frequencyName = frequency && frequency.label;
        });
        setHistoryData(formatted_data);
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to fetch historical Community Fees');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const deleteCommunityFeesIncentive = (item: Partial<CommunityFeeDateType>) => {
    setDataLoading(STATUS.LOADING);
    axios
      .delete(`/communities/${communityId}/fee-incentives/${item.id}`)
      .then((response) => {
        const fee_index = historyData.findIndex((fee) => fee.id === item.id);
        historyData.splice(fee_index, 1);
        setHistoryData([...historyData]);
        showMessage('success', 'Historical Community Fee deleted successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to delete historical Community Fee');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const getTooltipContent = (record: any) => {
    return (
      <TooltipContent>
        <span>Created By: {record?.createdBy}</span>
        <span>Created Date: {dayjs(record?.createdAt).format(GLOBAL_DATE_FORMAT)}</span>
        <span>Updated By: {record?.updatedBy}</span>
        <span>Updated Date: {dayjs(record?.updatedAt).format(GLOBAL_DATE_FORMAT)}</span>
      </TooltipContent>
    );
  };

  const columns = [
    {
      title: 'Living Type',
      dataIndex: 'livingType',
      render: (value: string, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Community Fee',
      dataIndex: 'communityFee',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Community Fee Equivalent',
      dataIndex: 'frequencyName',
      render: (value: string, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: '2nd Person Fee',
      dataIndex: 'secondPersonFee',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Pet Fee',
      dataIndex: 'petFee',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Parking Fee',
      dataIndex: 'parkingFee',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Respite Fee',
      dataIndex: 'respiteFee',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Additional Fee',
      dataIndex: 'additionalFee',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Move In Fee',
      dataIndex: 'moveInFee',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Entrance Fee',
      dataIndex: 'entranceFee',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Deposit Fee',
      dataIndex: 'deposit',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Deposit Equivalent',
      dataIndex: 'depositEquivalentFactor',
      render: (value: string, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Emergency Pendant Fee',
      dataIndex: 'emergencyPendantFee',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Incentives Value',
      dataIndex: 'incentiveValue',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Incentives',
      dataIndex: 'incentive',
      render: (value: string, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Data Collected Date',
      dataIndex: 'date',
      render: (value: Dayjs, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value && value.format(GLOBAL_DATE_FORMAT)}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Actions',
      hidden: missingDeletionPermission,
      render: (_: any, record: Partial<CommunityFeeDateType>) => {
        return (
          <ButtonContainer>
            <Popconfirm
              title="Are you sure you want to delete this record?"
              placement="left"
              onConfirm={() => deleteCommunityFeesIncentive(record)}
            >
              <Button
                size="small"
                type="link"
                danger
                icon={<DeleteFilled />}
                disabled={dataLoading === STATUS.LOADING}
              />
            </Popconfirm>
          </ButtonContainer>
        );
      },
    },
  ];

  return (
    <div>
      <Typography.Title level={3}>Community Fees</Typography.Title>
      <Table
        bordered
        loading={dataLoading === STATUS.LOADING}
        scroll={{ x: MEDIA_BREAK_POINTS.MOBILE }}
        columns={columns}
        dataSource={historyData}
      />
    </div>
  );
};

export default FeesFormHistoryCommunityFees;

import { CommunityLevelFeeOutbound, FeeParams } from 'pages/price_healing/Accommodation/types';

import { CommunityCareTypeQueryInterface, FullHomeCommunitiesTypeQueryInterface } from './types';
import { filterUndefinedParams } from './utils';
import axios from '../config/axiosPrivate';

export const getCommunity = async (communityId: number) => {
  const response = await axios.get(`/communities/${communityId}`);
  return response.data;
};

export const updateCommunity = async (communityId: number, payload: any) => {
  const url = `/communities/${communityId}`;

  const response = await axios.patch(url, payload);
  return response.data;
};

export const getCompetitors = async (communityId: number) => {
  const response = await axios.get(`/communities/${communityId}/competitors`);
  return response.data;
};

export const GetFullHomeCommunities = async (params: FullHomeCommunitiesTypeQueryInterface) => {
  const url = `/communities/Get_FullHomeCommunities/`;
  const response = await axios.post(url, params);
  return response.data?.payload;
};

export const getCommunityCareTypes = async (communityId: number, params: CommunityCareTypeQueryInterface) => {
  const cleanParams = filterUndefinedParams(params);
  const query = new URLSearchParams(cleanParams).toString();

  const url = `/communities/${communityId}/dashboard/care-types?${query}`;
  const response = await axios.get(url);
  return response.data;
};

export const getCommunityRentSquareFootageData = async (
  communityId: number,
  params: CommunityCareTypeQueryInterface
) => {
  const cleanParams = filterUndefinedParams(params);
  const query = new URLSearchParams(cleanParams).toString();

  const url = `/communities/${communityId}/dashboard/rent-square-footage?${query}`;
  const response = await axios.get(url);
  return response.data;
};

export const getCommunityRentAverageCareCostData = async (
  communityId: number,
  params: CommunityCareTypeQueryInterface
) => {
  const cleanParams = filterUndefinedParams(params);
  const query = new URLSearchParams(cleanParams).toString();

  const url = `/communities/${communityId}/dashboard/rent-average-care-cost?${query}`;
  const response = await axios.get(url);
  return response.data;
};

export const getCommunityRating = async (communityId: number) => {
  const url = `/communities/${communityId}/dashboard/google-rating-chart`;
  const response = await axios.get(url);
  return response.data;
};

export const getCommunityFee = async (communityId: number, params: CommunityCareTypeQueryInterface) => {
  const cleanParams = filterUndefinedParams(params);
  const query = new URLSearchParams(cleanParams).toString();

  const url = `/communities/${communityId}/dashboard/fee-comparison-graph?${query}`;
  const response = await axios.get(url);
  return response.data;
};

export const getCommunitySecondPersonFee = async (communityId: number, params: CommunityCareTypeQueryInterface) => {
  const cleanParams = filterUndefinedParams(params);
  const query = new URLSearchParams(cleanParams).toString();

  const url = `/communities/${communityId}/dashboard/second-person-fees-chart?${query}`;
  const response = await axios.get(url);
  return response.data;
};

export const getCommunityOccupancies = async (communityId: number, params: CommunityCareTypeQueryInterface) => {
  const cleanParams = filterUndefinedParams(params);
  const query = new URLSearchParams(cleanParams).toString();

  const url = `/communities/${communityId}/dashboard/community-occupancy-chart?${query}`;
  const response = await axios.get(url);
  return response.data;
};

export const getCommunityIncentives = async (communityId: number, params: CommunityCareTypeQueryInterface) => {
  const cleanParams = filterUndefinedParams(params);
  const query = new URLSearchParams(cleanParams).toString();

  const url = `/communities/${communityId}/dashboard/fees-incentives?${query}`;
  const response = await axios.get(url);
  return response.data;
};

export const getCommunityMarketAverages = async (communityId: number) => {
  const url = `/communities/Get_CommunityInfo/${communityId}/`;
  const response = await axios.get(url);
  return response.data;
};

export const getCommunityImages = async (communityId: number, params: any) => {
  const url = `/communities/Get_CommunityCompareImages/${communityId}/`;
  const response = await axios.get(url, { params });
  return response.data;
};

export const getAllCommunities = async (params: any) => {
  const url = '/communities';
  const response = await axios.get(url, { params });
  return response.data;
};

export const getCommunityLevelFees = async (communityId: number, params?: FeeParams) => {
  const cleanParams = filterUndefinedParams(params);
  const query = new URLSearchParams(cleanParams).toString();

  const url = `communities/${communityId}/community-level-fees?${query}`;
  const response = await axios.get(url);
  return response.data;
};

export const createCommunityLevelFees = async (
  communityId: number,
  params: CommunityLevelFeeOutbound,
  queryParams?: FeeParams
) => {
  const url = `communities/${communityId}/community-level-fees/create`;
  const response = await axios.post(url, params);
  return response.data;
};

export const updateCommunityLevelFees = async (
  communityId: number,
  params: CommunityLevelFeeOutbound,
  queryParams?: FeeParams
) => {
  const cleanParams = filterUndefinedParams(queryParams);
  const query = new URLSearchParams(cleanParams).toString();

  const url = `communities/${communityId}/community-level-fees/${params.id}?${query}`;
  const response = await axios.patch(url, params);
  return response.data;
};
